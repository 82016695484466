import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Arrow from "../images/arrow.inline.svg";
import IconOne from "../images/lighticon.inline.svg";
import IconTwo from "../images/handicon.inline.svg";
import IconThree from "../images/upicon.inline.svg";

import Right from "../images/toright.inline.svg";

function IndexPage({ data, pageContext: { lang, currentPath } }) {
  return (
    <Layout>
      <SEO
        title="Careers"
        description="If you value personal and professional growth as much as we do, a role with LeverID might be in your future."
        image="https://www.leverid.com/thumbnail-new.png"
      />
      <Container className="home-first fullwidth banner-section regularheader">
        <Row className="container">
          <Col>
            <h4>CAREERS</h4>
            <h1>Find your opportunity to grow and excel with LeverID</h1>
            <p>
              If you value personal and professional growth as much as we do, a
              role with LeverID might be in your future.
            </p>
            <a href="#jobs">
              Job openings
              <Arrow />
            </a>
          </Col>
          <Col className="bannerimage">
            <Img fluid={data.careersmain.childImageSharp.fluid} />
          </Col>
        </Row>
      </Container>

      <Container className="home-third  fullwidth bluelines career-sub">
        <Container>
          <Row>
            <h2>
              LeverID is an innovative and future-focused IT collective that
              ideates, builds, and implements software solutions for a
              post-quantum world.
            </h2>
          </Row>
        </Container>
      </Container>

      <Container className="home-third  fullwidth bluelines career-second">
        <Container>
          <Row>
            <h2>Our values</h2>
          </Row>
          <Row>
            <Col>
              <IconOne />
              <h3>Innovation</h3>
              <p>
                Building solutions that will stand the test of time through
                diverse thinking.
              </p>
            </Col>
            <Col>
              <IconTwo />
              <h3>Cooperation</h3>
              <p>
                Working as a collective is the key pillar that allows us to grow
                as a team, and as individuals.
              </p>
            </Col>
            <Col>
              <IconThree />
              <h3>Directness</h3>
              <p>
                Honesty with each other and our clients ensures all-around
                success.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="fullwidth home-fift centered-text custom-order-careers">
        <h2>Working at LeverID</h2>
        <Container>
          <Row>
            <Col>
              <Img fluid={data.conone.childImageSharp.fluid} />
            </Col>
            <Col>
              <h3>Growth</h3>
              <p>
                From our genesis, we’ve consistently strived to foster an
                environment that allows burgeoning talent to realise its full
                potential. Once you step through our doors as an employee, you
                can expect empowerment and development on both a professional
                and personal level.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Work-life balance</h3>
              <p>
                We’re far greater than the sum of our parts. Although we behave
                as one, we’re perpetually looking out for and supporting each
                other to ensure we leave the office with as much mental
                fortitude and confidence as when we enter it.
              </p>
            </Col>
            <Col>
              <Img fluid={data.contwo.childImageSharp.fluid} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Img fluid={data.conthree.childImageSharp.fluid} />
            </Col>
            <Col>
              <h3>Changing the future</h3>
              <p>
                We maintain ever-increasing momentum towards breaching the outer
                bounds in the world of IT in order to push the industry forward
                with projects designed for a better tomorrow that will never
                leave you.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container id="jobs" className="fullwidth list-of-career">
        <Container>
          <Row>
            <h2>Current openings</h2>
          </Row>
          {false ? (
            // {!!data.prismic.allCareers.edges[0] ? (
            <>
              {/* <Row className="desktop-job-list">
                <table>
                  <tr className="table-header">
                    <th>Position</th>
                    <th>Department</th>
                    <th>Location</th>
                    <th>Deadline</th>
                    <th></th>
                  </tr>
                  {data.prismic.allCareers.edges[0].node.openings.map(
                    (node) => (
                      <tr>
                        <td>{node.position[0].text}</td>
                        <td>{node.department[0].text}</td>
                        <td>{node.location[0].text}</td>
                        <td>{node.deadline[0].text}</td>
                        <td>
                          <a
                            href={node.apply[0].text}
                            target="_blank"
                            className="apply-to"
                          >
                            Apply
                          </a>
                          <a
                            href={node.read_more[0].text}
                            target="_blank"
                            className="regular-link-more"
                          >
                            More info
                          </a>
                        </td>
                      </tr>
                    )
                  )}
                </table>
              </Row>
              <Row className="job-list-mobile">
                {data.prismic.allCareers.edges[0].node.openings.map((node) => (
                  <Col>
                    <Row>
                      <strong>Position: </strong> {node.position[0].text}
                    </Row>
                    <Row>
                      <strong>Department: </strong> {node.department[0].text}
                    </Row>
                    <Row>
                      <strong>Location: </strong> {node.location[0].text}
                    </Row>
                    <Row>
                      <strong>Deadline: </strong> {node.deadline[0].text}
                    </Row>
                    <Row>
                      <a
                        href={node.apply[0].text}
                        target="_blank"
                        className="apply-to"
                      >
                        Apply
                      </a>
                      <a
                        href={node.read_more[0].text}
                        target="_blank"
                        className="regular-link-more"
                      >
                        More info
                      </a>
                    </Row>
                  </Col>
                ))}
              </Row> */}
            </>
          ) : (
            <Row className="no-jobs">
              <p>Currently there are no open positions.</p>
            </Row>
          )}
          <Row>
            <p class="after-job">
              Didn’t find a suitable position?{" "}
              <Link to="/career-contact">
                Leave us your contact details.
                <Right />
              </Link>
            </p>
          </Row>
        </Container>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query {
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rihofull: file(relativePath: { eq: "riho_full.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    careersmain: file(relativePath: { eq: "careers.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    conone: file(relativePath: { eq: "careerone.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contwo: file(relativePath: { eq: "careertwo.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    conthree: file(relativePath: { eq: "careerthree.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export default IndexPage;
